<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="sendData">
      <div class="form-page">
        <router-link class="back" :to="{ name: '表單項目' }">
          <eva-icon fill="#FFF" name="corner-up-left-outline"></eva-icon>
        </router-link>
        <div class="form-header">每日檢查項目表單</div>

        <div class="form-content">
          <div class="page-wrap" v-if="currentPage === 1">
            <div class="page" v-if="currentPage === 1">
              <div class="form-title">表單資訊</div>
              <div class="form-group">
                <label
                  for="inputDriver"
                  class="col-xs-3 col-sm-2 control-label red"
                  >DC別</label
                >
                <div class="col-xs-9 col-sm-10">
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.StoreCode"
                    readonly
                  />
                </div>
              </div>
              <div class="form-group">
                <label
                  for="inputPlateNumer"
                  class="col-xs-3 col-sm-2 control-label red"
                  >組別</label
                >
                <div class="col-xs-9 col-sm-10">
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.GroupName"
                    readonly
                  />
                </div>
              </div>
              <div class="form-group">
                <label
                  for="inputDate"
                  class="col-xs-3 col-sm-2 control-label red"
                  >車輛保管人</label
                >
                <div class="col-xs-9 col-sm-10">
                  <input
                    v-model="form.DriverAccount"
                    type="text"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>
              <div class="form-group">
                <label
                  for="inputStartMileage"
                  class="col-xs-3 col-sm-2 control-label red"
                  ><span class="text-danger">*</span>車號</label
                >
                <div class="col-xs-9 col-sm-10">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <!-- <el-autocomplete
                      v-model="form.LicensePlateNo"
                      :fetch-suggestions="querySearch"
                      placeholder="請選擇或輸入車號"
                      style="width: 100%"
                      @select="changeCarNumber"
                    ></el-autocomplete> -->

                    <multiselect
                      v-model="form.LicensePlateNo"
                      tag-placeholder="新增車號"
                      placeholder="請選擇或輸入新車號"
                      :options="carNumList"
                      selectLabel="點擊選取"
                      deselectLabel="點擊取消選取"
                      selectedLabel="已選擇"
                      :multiple="false"
                      :taggable="true"
                      :reset-after="false"
                      @tag="addTag"
                      @select="changeCarNumber"
                    ></multiselect>

                    <span v-if="errors[0]" class="text-danger ml-1">{{
                      errors[0]
                    }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="form-group">
                <label class="col-xs-3 col-sm-2 control-label">日期</label>
                <div class="col-xs-9 col-sm-10">
                  <div
                    :class="{ 'red-control': week === '星期日' }"
                    class="form-control date-control"
                    v-if="form.Date !== null"
                  >
                    {{ form.Date }}{{ week }}
                  </div>
                  <div
                    :class="{
                      'red-day-wrap': week === '星期日',
                      'gray-day-wrap': week === '',
                    }"
                    class="day-wrap"
                    v-if="form.Date === null"
                  >
                    <el-date-picker
                      style="width: 100%"
                      v-model="form.Date"
                      :clearable="false"
                      :disabled="true"
                      :picker-options="pickerOptions"
                      type="date"
                      placeholder="選擇日期"
                      value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="col-xs-offset-3 col-xs-9 col-sm-offset-2 col-sm-10">
                  <div class="checkbox" id="isMaintained">
                    <label>
                      <input type="checkbox" v-model="form.noDrive" />
                      未開（無里程）<span style="color: red; font-weight: bold"
                        >(維修、保養、檢驗、排休、未開、店休請務必勾選未開)</span
                      >
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="page-wrap"
            v-for="(item, index) of pageList"
            :key="index"
            v-show="currentPage === index + 2"
          >
            <div class="page" v-if="currentPage === index + 2">
              <div class="form-title">{{ item.name }}</div>

              <div
                class="form-group row"
                v-for="(checkItem, index) of item.list"
                :key="checkItem.InspectionItemGuid"
              >
                <label class="col-xs-12 col-sm-6"
                  ><span class="text-danger">*</span>{{ index + 1 }}.{{
                    checkItem.Item
                  }}{{ checkItem.Standards }}</label
                >

                <div class="col-xs-12 col-sm-6">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="radio-container">
                      <label>
                        <input
                          type="radio"
                          :value="true"
                          :name="checkItem.InspectionItemGuid"
                          v-model="checkItem.IsQualified"
                        />
                        <span
                          ><eva-icon name="checkmark-outline"></eva-icon>合
                          格</span
                        >
                      </label>
                      <label>
                        <input
                          type="radio"
                          :value="false"
                          :name="checkItem.InspectionItemGuid"
                          v-model="checkItem.IsQualified"
                        />
                        <span
                          ><eva-icon name="close-outline"></eva-icon
                          >不合格</span
                        >
                      </label>
                    </div>
                    <span
                      style="display: block; text-align: center"
                      v-if="errors[0]"
                      class="text-danger ml-1"
                      >{{ errors[0] }}</span
                    >
                  </ValidationProvider>
                </div>
                <div class="col-12">
                  <div class="flex" v-if="checkItem.IsQualified !== false">
                    備註<input
                      type="text"
                      class="form-control"
                      v-model="checkItem.Notes"
                    />
                  </div>
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                    v-if="checkItem.IsQualified === false"
                  >
                    <div class="flex">
                      備註<input
                        type="text"
                        class="form-control"
                        v-model="checkItem.Notes"
                      />
                    </div>
                    <span
                      style="display: block; text-align: center"
                      v-if="errors[0]"
                      class="text-danger ml-1"
                      >{{ errors[0] }}</span
                    >
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>

          <div class="page-wrap" v-if="currentPage === 3">
            <div class="page">
              <div class="form-title">
                (二)車廂裝載貨物前低溫機組溫度記錄<br />
                (冷凍：-12℃/冷藏：7℃)
              </div>
              <div class="form-group row">
                <div class="col-12">
                  <button type="button" class="add" @click="addCheckTemp">
                    +
                  </button>

                  <span
                    class="ml-2"
                    v-show="
                      form.TemperatureRecords &&
                      form.TemperatureRecords.length === 0
                    "
                    >暫無紀錄</span
                  >
                </div>

                <div
                  class="trips-item col-12"
                  v-for="(i, index) in form.TemperatureRecords"
                  :key="i.TripSeq"
                >
                  <label> 第{{ i.TripSeq }}趟 </label>
                  <el-checkbox
                    @change="checkTemp(i)"
                    v-model="i.IsNormalTemperature"
                    :disabled="i.IsFreezing || i.IsRefrigeration"
                    >全車常溫</el-checkbox
                  >

                  <el-checkbox @change="chnageTemp(i, 'refrigeration')" v-model="i.IsRefrigeration" :disabled="i.IsNormalTemperature">冷藏</el-checkbox>
                  <el-checkbox @change="chnageTemp(i, 'freezing')" v-model="i.IsFreezing" :disabled="i.IsNormalTemperature">冷凍</el-checkbox>

                  <div class="flex">
                    <div class="temperature mr-2">
                      冷藏
                      <span v-if="!i.IsRefrigeration">
                        <input
                          type="text"
                          :disabled="i.IsNormalTemperature || !i.IsRefrigeration"
                          v-model="i.Refrigeration"
                          class="form-control"
                        />
                      </span>

                      <ValidationProvider
                        v-if="i.IsRefrigeration"
                        :rules="(i.Freezing === '' || i.Freezing === null || i.IsRefrigeration) ? 'required' : null"
                        v-slot="{ errors }"
                      >
                        <input
                          type="text"
                          :disabled="i.IsNormalTemperature"
                          v-model="i.Refrigeration"
                          class="form-control"
                        />
                        <span>{{ errors[0] }}</span>
                      </ValidationProvider>

                      °C
                    </div>
                    <div class="temperature mr-2">
                      冷凍
                      <input
                          v-if="i.IsNormalTemperature"
                          type="text"
                          :disabled="i.IsNormalTemperature || !i.IsFreezing"
                          v-model="i.Freezing"
                          class="form-control"
                        />
                      <ValidationProvider
                        v-if="!i.IsNormalTemperature"
                        :rules="{'requiredOne': (!i.IsFreezing && !i.IsRefrigeration) ? true : false, 'required': i.IsFreezing ? true : false}"
                        v-slot="{ errors }"
                      >
                        <input
                          type="text"
                          :disabled="i.IsNormalTemperature || !i.IsFreezing"
                          v-model="i.Freezing"
                          class="form-control"
                        />
                        <span>{{ errors[0] }}</span>
                      </ValidationProvider>

                      °C
                    </div>

                    
                    <button
                    type="button"
                    v-show="form.TemperatureRecords.length > 1"
                    @click.prevent="deleteTemperature(index)"
                    class="btn btn-danger btn-sm"
                  >
                    <eva-icon fill="#FFF" name="trash-2-outline"></eva-icon>
                  </button>
                  </div>

                  <!-- <label>
                  <input v-model="i.normalTemperature" type="checkbox" />
                  
                </label> -->
                  
                </div>
              </div>

              <div class="form-group row">
                <label class="col-xs-12 col-sm-6"
                  ><span class="text-danger">*</span>行駛中車廂可持續降溫</label
                >

                <div class="col-xs-12 col-sm-6">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="radio-container">
                      <label>
                        <input
                          type="radio"
                          :value="true"
                          name="IsCoolingTemperature"
                          v-model="form.IsCoolingTemperature"
                        />
                        <span
                          ><eva-icon name="checkmark-outline"></eva-icon>合
                          格</span
                        >
                      </label>
                      <label>
                        <input
                          type="radio"
                          :value="false"
                          name="IsCoolingTemperature"
                          v-model="form.IsCoolingTemperature"
                        />
                        <span
                          ><eva-icon name="close-outline"></eva-icon
                          >不合格</span
                        >
                      </label>
                    </div>
                    <span
                      style="display: block; text-align: center"
                      v-if="errors[0]"
                      class="text-danger ml-1"
                      >{{ errors[0] }}</span
                    >
                  </ValidationProvider>
                </div>
              </div>
              <div class="form-group">
                <label>
                  <span
                    class="text-danger"
                    v-if="form.IsCoolingTemperature === false"
                    >*</span
                  >
                  車輛/車廂溫度異常原因或事故調度說明
                </label>
                <textarea
                  v-if="form.IsCoolingTemperature !== false"
                  class="form-control"
                  name="TemperatureReason"
                  v-model="form.TemperatureReason"
                  cols="30"
                  rows="10"
                ></textarea>
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                  v-if="form.IsCoolingTemperature === false"
                >
                  <textarea
                    class="form-control"
                    name="TemperatureReason"
                    v-model="form.TemperatureReason"
                    cols="30"
                    rows="10"
                  ></textarea>
                  <span
                    style="display: block; text-align: center"
                    v-if="errors[0]"
                    class="text-danger ml-1"
                    >{{ errors[0] }}</span
                  >
                </ValidationProvider>
              </div>
            </div>
          </div>

          <div class="button-wrap">
            <span v-if="currentPage === 1" style="opacity: 0.3"
              ><eva-icon name="chevron-left-outline"></eva-icon>上一步</span
            >
            <span
              v-if="currentPage !== 1"
              style="cursor: pointer"
              @click="currentPage -= 1"
              ><eva-icon name="chevron-left-outline"></eva-icon>上一步</span
            >
            <div>
              <span class="current">{{ currentPage }}</span
              >/3
            </div>
            <button
              v-if="currentPage !== 3 && !form.noDrive"
              style="cursor: pointer"
              type="submit"
            >
              下一步<eva-icon name="chevron-right-outline"></eva-icon>
            </button>
            <span v-if="currentPage === 3 || form.noDrive" style="opacity: 0.3"
              >下一步<eva-icon name="chevron-right-outline"></eva-icon
            ></span>
          </div>
        </div>
        <button type="submit" class="btn btn-block btn-danger btn-save">
          儲存
        </button>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { Notification } from "element-ui";
export default {
  data() {
    return {
      user: {},
      currentPage: 1,
      pickerOptions: {},
      form: {
        Date: null,
        DailyInspectionGuid: null,
        TemperatureRecords: [],
        TemperatureReason: null,
        IsCoolingTemperature: null,
        InspectionItems: [],
        noDrive: false,
      },
      tempData: null,

      carNumList: [],
      pageList: [], //用來讓客戶選填的
      clearList: [], //乾淨的
      keepList: [], //有資料的

      index: 3,
      weekArr: [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ],
      week: "",
      // rows: [
      //   {
      //     disable: false,
      //     check: false,
      //     freezing: -18,
      //     refrigeration: 7,
      //     normalTemperature: false,
      //   },
      //   {
      //     disable: false,
      //     check: false,
      //     freezing: -18,
      //     refrigeration: 7,
      //     normalTemperature: false,
      //   },
      // ],
    };
  },
  methods: {
    goComplete() {
      this.$router.push({ name: "每日檢察項目表單完成" });
    },

    getCheckList() {
      const vm = this;
      const api = `${process.env.VUE_APP_API}/VehicleMaintenance/Inspection/GetInspectionItems`;

      const params = {
        formType: "Daily",
      };
      //vm.$store.dispatch("updateLoading", true);
      vm.$http
        .get(api, { params })
        .then((response) => {
          console.log("check-response", response);

          let entireDate = Object.entries(response.data.Data.Daily);

          entireDate.forEach((item) => {
            this.clearList.push({
              name: item[0],
              list: item[1],
            });
          });

          this.clearList.forEach((item) => {
            item.list = item.list.map((innerItem) => {
              return {
                ...innerItem,
                Notes: "",
                IsQualified: null,
              };
            });
          });

          //this.keepList=[...this.clearList];

          this.keepList = JSON.parse(JSON.stringify(this.clearList));
        })
        .catch((error) => {
          //vm.$store.dispatch("updateLoading", false);
          Notification({
            title: "錯誤",
            message: error.response.data,
            type: "error",
            duration: 2000,
          });
        });
    },

    //變更車號時需同時變更DC別 並且跑get api撈出之前寫過的資料
    changeCarNumber(selVal) {
      const vm = this;
      const api = `${process.env.VUE_APP_API}/VehicleMaintenance/Inspection/GetDailyInspection/${this.user.Account}/${selVal}`;

      //vm.$store.dispatch("updateLoading", true);
      vm.$http
        .get(api)
        .then((response) => {
          console.log("respresponseresponseresponseonse", response.data.Data);

          if (response.data.Data.DailyInspectionGuid !== null) {
            this.tempData = response.data.Data;
            this.form = {
              ...this.form,
              DailyInspectionGuid: response.data.Data.DailyInspectionGuid,
              TemperatureRecords:
                response.data.Data.TB_CAR_DAILY_INSPECTION_TEMPERATUREs,
              TemperatureReason: response.data.Data.TemperatureReason,
              IsCoolingTemperature: response.data.Data.IsCoolingTemperature,
              InspectionItems:
                response.data.Data.TB_CAR_DAILY_INSPECTION_DETAILs,
              noDrive: !response.data.Data.IsDriving,
            };

            this.keepList.forEach((item) => {
              item.list.map((innerItem) => {
                response.data.Data.TB_CAR_DAILY_INSPECTION_DETAILs.find(
                  (dItem) => {
                    if (
                      dItem.InspectionItemGuid === innerItem.InspectionItemGuid
                    ) {
                      innerItem.IsQualified = dItem.IsQualified;
                      innerItem.Notes = dItem.Notes;
                    }
                  }
                );
              });
            });

            this.pageList = JSON.parse(JSON.stringify(this.keepList));
          } else {
            this.form.DailyInspectionGuid = null;
            this.form.TemperatureRecords = [];
            this.form.TemperatureReason = null;
            this.form.IsCoolingTemperature = null;
            this.form.InspectionItems = [];
            this.form.UpdateUser = this.user.Account;

            this.pageList = JSON.parse(JSON.stringify(this.clearList));
          }

          if (this.form.TemperatureRecords.length < 1) {
            this.form.TemperatureRecords = [
              {
                Degree: "C",
                Freezing: null,
                IsNormalTemperature: false,
                Refrigeration: null,
                TripSeq: 1,
                IsRefrigeration: false,
                IsFreezing: false,
              },
            ];
          }

          this.form.Date = vm.dayjs(response.data.Data.Date).format("YYYY-MM-DD");

          const moonStartLanding = new Date(response.data.Data.Date);

          this.pickerOptions = {
            disabledDate(date) {
              return date < moonStartLanding || date > moonStartLanding;
            },
          };

          const getWeek = new Date(vm.form.Date).getDay();
          vm.week = vm.weekArr[getWeek];
        })
        .catch((error) => {
          //vm.$store.dispatch("updateLoading", false);
          Notification({
            title: "錯誤",
            message: error.response.data,
            type: "error",
            duration: 2000,
          });
        });
    },

    checkTemp(item) {
      item.Freezing = null;
      item.Refrigeration = null;

      item.IsRefrigeration = false;
      item.IsFreezing = false; 
    },

    chnageTemp(item, type) {  //  改變冷凍、冷藏時，將全車常溫改掉
      item.IsNormalTemperature = false

      if(type === 'freezing') {
        item.IsFreezing ? item.Freezing : item.Freezing = null
      } else {
        item.IsRefrigeration ? item.Refrigeration : item.Refrigeration = null
      }
    },

    addCheckTemp() {
      this.form.TemperatureRecords.push({
        TripSeq: this.form.TemperatureRecords.length + 1,
        Degree: "C",
        Freezing: null,
        Refrigeration: null,
        IsNormalTemperature: false,
        IsRefrigeration: false,
        IsFreezing: false,
      });
    },

    deleteTemperature(deleteIndex) {
      this.form.TemperatureRecords.splice(deleteIndex, 1);
      this.form.TemperatureRecords = this.form.TemperatureRecords.map(function (
        item,
        index
      ) {
        return {
          ...item,
          TripSeq: index + 1,
        };
      });
    },

    async sendData() {
      const isValid = await this.$refs.form.validate();

      if (!isValid) {
        return;
      }
      if (this.currentPage === 3 || this.form.noDrive) {
        //const vm = this;
        //const api = `${process.env.VUE_APP_API}/VehicleMaintenance/Inspection/SetWeeklyInspection`;

        this.form.InspectionItems = [];

        this.pageList.forEach((item) => {
          this.form.InspectionItems.push(...item.list);
        });

        let httpMethod = "";
        let api = "";

        this.form.LicensePlateNo = this.form.LicensePlateNo.toUpperCase();

        this.form.IsDriving = !this.form.noDrive;

        //有兩個狀況  如果有guid要用put 沒guid要post
        if (this.form.DailyInspectionGuid !== null) {
          httpMethod = "put";
          api = `${process.env.VUE_APP_API}/VehicleMaintenance/Inspection/UpdateDailyInspection`;
          this.form.UpdateUser = this.user.Account;
        } else {
          httpMethod = "post";
          api = `${process.env.VUE_APP_API}/VehicleMaintenance/Inspection/AddDailyInspection`;
          this.form.CreateUser = this.user.Account;
        }

        if (this.form.noDrive) {
          this.form.TemperatureRecords = null;
          this.form.InspectionItems = null;
          this.form.TemperatureReason = null;
          this.form.IsCoolingTemperature = null;
        } else {
          this.form.TemperatureRecords = this.form.TemperatureRecords.map(
            (item) => {
              return {
                ...item,
                Freezing: item.Freezing === "" ? null : item.Freezing,
                Refrigeration:
                  item.Refrigeration === "" ? null : item.Refrigeration,
              };
            }
          );
        }

        const vm = this;

        console.log("this.form", this.form);

        //vm.$store.dispatch("updateLoading", true);
        vm.$http[httpMethod](api, this.form)

          .then((response) => {
            console.log("response", response);
            if (response.data.ErrorMessage === null) {
              this.$router.push({ name: "每日檢察項目表單完成" });
            } else {
              console.log("response.data", response.data);
              Notification({
                title: "錯誤",
                message: response.data.ErrorMessage,
                type: "error",
                duration: 2000,
              });
            }
          })
          .catch((error) => {
            //vm.$store.dispatch("updateLoading", false);            
            Notification({
              title: "錯誤",
              message: error.response.data,
              type: "error",
              duration: 2000,
            });
          });

        //送出
      } else {
        this.currentPage += 1;
      }
    },

    addTag(newTag) {
      this.carNumList.push(newTag);
      this.form.LicensePlateNo = newTag;

      this.changeCarNumber(newTag);
    },

    // querySearch(queryString, cb) {
    //   var restaurants = this.carNumList;
    //   var results = queryString
    //     ? restaurants.filter(this.createFilter(queryString))
    //     : restaurants;

    //   cb(results);
    // },
    // createFilter(queryString) {
    //   return (restaurant) => {
    //     return (
    //       restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
    //       0
    //     );
    //   };
    // },
  },
  created() {
    this.getCheckList();

    this.user = JSON.parse(localStorage.getItem("user"));

    this.form.DriverAccount = this.user.Account; //這三個不會動
    this.form.GroupCode = this.user.Driver.GroupId; //這三個不會動
    this.form.GroupName = this.user.Driver.Group; //這三個不會動

    if (this.user.Driver.DriverVehicles.length > 0) {
      this.form.StoreCode = this.user.Driver.DriverVehicles[0].STOR_CDX;
      //this.carNumList = [...this.user.Driver.DriverVehicles];
      // this.carNumList = this.user.Driver.DriverVehicles.map((item) => {
      //   return {
      //     value: item.CARX_NOX,
      //   };
      // });

      //找出DRIV_MAN等於駕駛的
      let defaultCar = this.user.Driver.DriverVehicles.find((item) => {
        return item.DRIV_MAN === this.user.Account;
      });
      this.form.LicensePlateNo = defaultCar.CARX_NOX;
      this.changeCarNumber(this.form.LicensePlateNo);

      this.carNumList = this.user.Driver.DriverVehicles.map((item) => {
        return item.CARX_NOX;
      });
    } else {
      console.log("沒有車牌");
    }
  },
};
</script>

